import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_ELEMENT = false;
export default {
    async dummyDataELEMENT(){
        return  new Promise((resolve) => {
            var datas = [];
            resolve(datas);
        });
    },

    async storeDataELEMENT(){
        return new Promise((resolve) => {
            var url = DataHelper.URL_ELEMENT();
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataELEMENT(){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_ELEMENT == true){
                var datas = await this.dummyDataELEMENT();
                resolve(datas);
            }else{
                var thisData = await this.storeDataELEMENT();
                var datas = thisData.data;
                resolve(datas);
            }
        });
    }
}