<template>
  <div>
    <b-card>
      <h6 class="text-dark">Quality Assesment Visualization</h6>
      <hr />
      <b-row>
        <b-col sm="12">
          <a-spin :spinning="LOADING_PAGE">
            <b-row class="">
              <b-col cols="2 pad2">
                <b-form-group>
                  <label>Usage </label>
                  <a-select show-search :value="USAGE_SELECTED" style="width: 100%" :filter-option="false" :not-found-content="null" @change="onChangeUSAGE">
                    <a-select-option v-for="(resource, indexResource) in STORE_USAGE" :key="'USAGE_' + indexResource" :value="resource.KEY">
                      {{ resource.LABEL }}
                    </a-select-option>
                  </a-select>
                </b-form-group>
              </b-col>

              <b-col cols="2 pad2">
                <b-form-group>
                  <label>Provinsi</label>
                  <a-select show-search :value="PROVINCE_SELECTED" style="width: 100%" :filter-option="false" @search="handleSearchProvince" @change="onChangePROVINCE">
                    <a-select-option v-for="(provinsi, indexProvinsi) in STORE_PROVINCE_VIEW" :key="'PROPINSI_' + indexProvinsi" :value="provinsi.propinsi_id">
                      {{ provinsi.propinsi_name }}
                    </a-select-option>
                  </a-select>
                </b-form-group>
              </b-col>

              <b-col cols="2 pad2">
                <b-form-group label-size="sm" label="SEARCH" label-for="search_text">
                  <a-input id="search_text" placeholder="" v-model="SEARCH_TEXT" />
                </b-form-group>
              </b-col>

              <b-col cols="3 pad2">
                <b-form-group>
                  <label>Element</label>
                  <a-select show-search :value="ELEMENT_SELECTED" style="width: 100%" :filter-option="false" :not-found-content="null" @search="handleSearchElement" @change="onChangeELEMENT">
                    <a-select-option v-for="(element, indexElement) in STORE_ELEMENT_VIEW" :key="'ELEMENT_' + indexElement" :value="element.ae_id">
                      {{ element.ae_label }}
                    </a-select-option>
                  </a-select>
                </b-form-group>
              </b-col>

              <b-col cols="2 pad2">
                <b-form-group>
                  <label>Class</label>
                  <a-select show-search :value="CLASS_SELECTED" style="width: 100%" :filter-option="false" :not-found-content="null" @search="handleSearchClass" @change="onChangeCLASS">
                    <a-select-option v-for="(classes, indexClass) in STORE_CLASS_VIEW" :key="'CLASS_' + indexClass" :value="classes.key">
                      {{ classes.label }}
                    </a-select-option>
                  </a-select>
                </b-form-group>
              </b-col>
              <b-col cols="1 pad2 mt-2" style="text-align: right">
                <a-button type="primary" icon="search" @click="onSearchReport"> Fetch </a-button>
              </b-col>
            </b-row>
          </a-spin>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <!-- ==start-content-left== -->
        <!-- <b-col sm="12">
                    <div class="header-box-custome">
                        VIEW VISUALITATION
                    </div>
                </b-col> -->
        <b-col sm="12">
          <a-spin :spinning="LOADING_PAGE">
            <!-- <div v-if="(REPORT_VISUALITATION.DATAS == undefined ? 0 :REPORT_VISUALITATION.DATAS.length) === 0">
                            <div>
                                <a-empty />
                            </div>
                        </div> -->
            <!-- <div v-if="(REPORT_VISUALITATION.DATAS == undefined ? 0 :REPORT_VISUALITATION.DATAS.length) > 0">
                            <b-row class="">
                                <b-col cols="5 pad2">
                                    <b-form-group>
                                        <label style="width:100px;">DATA VISUAL :</label>
                                        <a-select
                                            show-search
                                            :value="VIEW_TYPE"
                                            style="width:200px"
                                            :filter-option="false"
                                            :not-found-content="null"
                                            @change="onChangeVIEWTYPE">
                                            <a-select-option v-for="(viewType,indexViewType)  in STORE_VIEW_TYPE" :key="'VIEWTYPE_'+indexViewType" :value="viewType.key">
                                            {{ viewType.label }}
                                            </a-select-option>
                                        </a-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div> -->
            <!-- <div v-if="(REPORT_VISUALITATION.DATAS == undefined ? 0 :REPORT_VISUALITATION.DATAS.length) > 0 && VIEW_TYPE == 'GRID'">
                            <DinamicTable :DATAS="REPORT_VISUALITATION" :ExportExcelName="ExportExcelName" @selectionChanged="selectionChanged" @onRowClick="onRowClick" ref="dinamicTableComponent"></DinamicTable>
                        </div> -->

            <!-- <div v-if="(REPORT_VISUALITATION.DATAS == undefined ? 0 :REPORT_VISUALITATION.DATAS.length) > 0 && VIEW_TYPE == 'MAP'"> -->
            <div>
              <DinamicMaps @onClickMarker="onClickMarker" ref="dinamicMapComponent"></DinamicMaps>
            </div>
          </a-spin>
        </b-col>
      </b-row>
    </b-card>
    <!-- STARTMODAL MARKER VIEW -->
    <a-modal v-model="this.MODAL_MARKER_SHOW" title="MARKER INFORMATION" @cancel="modalMarkerClose">
      <div>
        <table style="width: 100%; fon-size: 12px">
          <tbody>
            <tr v-for="(header, indexHeader) in HEADER_SHORT_VIEW" :key="indexHeader">
              <td style="font-weight: bold; width: 200px">{{ header.label }}</td>
              <td v-if="MODEL_INFO[header.label.split(' ').join('_')] != undefined">
                <span v-if="MODEL_INFO[header.label.split(' ').join('_')].flag == true">
                  <a-icon type="check" v-if="Number(MODEL_INFO[header.label.split(' ').join('_')].values) === 1" style="color: #00ff00" />
                  <a-icon type="close" v-if="Number(MODEL_INFO[header.label.split(' ').join('_')].values) === 0" style="color: #ff0000" />
                  <a-icon type="stop" v-if="MODEL_INFO[header.label.split(' ').join('_')].values === '-'" />
                </span>
                <span v-if="MODEL_INFO[header.label.split(' ').join('_')].flag == false">
                  {{ MODEL_INFO[header.label.split(" ").join("_")].values }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- START FOOTER MODAL -->
      <template slot="footer">
        <a-button key="submit" type="primary" @click="modalMarkerOk"> Ok </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BTabs,
  BTab,
  BFormDatepicker,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
} from "bootstrap-vue";

import DataELEMENT from "@/api/dummy/store/DataDummyELEMENT";
import DataUSAGE from "@/api/dummy/store/DataDummyUSAGE";
import DataRESOURCE from "@/api/dummy/store/DataDummyRESOURCE";
import DataREGION from "@/api/dummy/store/DataDummyREGION";
import DataPROVINCE from "@/api/dummy/store/DataDummyPROVINCE";
import DataKABUPATEN from "@/api/dummy/store/DataDummyKABUPATEN";
import DataDummyRAWVISUAL from "@/api/dummy/store/DataDummyRAWVISUAL";
import DataHelper from "@/api/dummy/store/DataHelper";
import moment from "moment";
import "leaflet/dist/leaflet.css";

import DinamicTable from "./DinamicTable.vue";
import DinamicMaps from "./DinamicMaps.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    DinamicTable,
    DinamicMaps,
  },
  methods: {
    async onLoadUsage() {
      this.LOADING_PAGE = true;
      const { items } = await DataUSAGE.callDataUSAGE();
      let usages = [];
      for (let usage of items) {
        if (usage.manage_usage_status) {
          let usg = { KEY: usage.manage_usage_id, LABEL: usage.manage_usage_name, CODE: usage["@name"] };
          usages.push(usg);
        }
      }
      this.STORE_USAGE = usages;
      this.LOADING_PAGE = false;
    },

    async onLoadPropinsi() {
      this.LOADING_PAGE = true;
      var data = await DataPROVINCE.callDataAllPROVINCE();
      this.STORE_PROVINCE = data;
      this.STORE_PROVINCE_VIEW = this.STORE_PROVINCE;
      this.LOADING_PAGE = false;
    },

    async onLoadElements() {
      this.LOADING_PAGE = true;
      var data = await DataELEMENT.callDataELEMENT();
      this.STORE_ELEMENT = data;
      this.STORE_ELEMENT_VIEW = this.STORE_ELEMENT;
      this.LOADING_PAGE = false;
    },

    onLoadClass() {
      this.STORE_CLASS = [
        { key: "CLASS_1", label: "CLASS 1" },
        { key: "CLASS_2", label: "CLASS 2" },
        { key: "CLASS_3", label: "CLASS 3" },
        { key: "CLASS_4", label: "CLASS 4" },
        { key: "CLASS_5", label: "CLASS 5" },
      ];

      this.STORE_CLASS_VIEW = this.STORE_CLASS;
    },

    async onLoadResource() {
      this.LOADING_PAGE = true;
      this.STORE_RESOURCE = await DataRESOURCE.callDataRESOURCE();
      this.LOADING_PAGE = false;
    },
    onChangeRangeDate(date, dateString) {
      this.DATE_RANGE_SELECTED = dateString;
    },
    onChangeDate(date, dateString) {
      this.DATE_SELECTED = dateString;
    },
    onChangeDatePeriode(date, dateString) {
      this.DATE_PERIODE_SELECTED = dateString;
    },
    handleSearchRegion(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var REGIONS = this.STORE_REGION;
      if (search == "" || search == null) {
        this.STORE_REGION_VIEW = this.STORE_REGION;
      }
      for (var i in REGIONS) {
        var regionName = REGIONS[i].region_description.toString().toLowerCase().search(temp);
        if (regionName != -1) {
          foundRaw.push(REGIONS[i]);
        }
      }
      this.STORE_REGION_VIEW = foundRaw;
    },

    handleSearchProvince(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var PROVINCE = this.STORE_PROVINCE;
      if (search == "" || search == null) {
        this.STORE_PROVINCE_VIEW = this.STORE_PROVINCE;
      }
      for (var i in PROVINCE) {
        var provinceName = PROVINCE[i].propinsi_name.toString().toLowerCase().search(temp);
        if (provinceName != -1) {
          foundRaw.push(PROVINCE[i]);
        }
      }
      this.STORE_PROVINCE_VIEW = foundRaw;
    },

    handleSearchElement(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var ELEMENT = this.STORE_ELEMENT;
      if (search == "" || search == null) {
        this.STORE_ELEMENT_VIEW = this.STORE_ELEMENT;
      }
      for (var i in ELEMENT) {
        var elementName = ELEMENT[i].ae_label.toString().toLowerCase().search(temp);
        if (elementName != -1) {
          foundRaw.push(ELEMENT[i]);
        }
      }
      this.STORE_ELEMENT_VIEW = foundRaw;
    },
    handleSearchClass(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var CLASS = this.STORE_CLASS;
      if (search == "" || search == null) {
        this.STORE_CLASS_VIEW = this.STORE_CLASS;
      }
      for (var i in CLASS) {
        var elementName = CLASS[i].ae_label.toString().toLowerCase().search(temp);
        if (elementName != -1) {
          foundRaw.push(CLASS[i]);
        }
      }
      this.STORE_CLASS_VIEW = foundRaw;
    },

    handleSearchDistrict(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var DISTRICT = this.STORE_DISTRICT;
      if (search == "" || search == null) {
        this.STORE_DISTRICT_VIEW = this.STORE_DISTRICT;
      }
      for (var i in DISTRICT) {
        var districtName = DISTRICT[i].kabupaten_name.toString().toLowerCase().search(temp);
        if (districtName != -1) {
          foundRaw.push(DISTRICT[i]);
        }
      }
      this.STORE_DISTRICT_VIEW = foundRaw;
    },
    async onChangeRESOURCE(values) {
      this.LOADING_PAGE = true;
      this.DATE_PERIODE_SELECTED = "";
      this.DATE_RANGE_SELECTED = null;
      this.DATE_SELECTED = "";
      this.RESOURECE_SELECTED = values;
      this.REGION_SELECTED = "";
      this.PROVINCE_SELECTED = "";
      this.DISTRICT_SELECTED = "";
      this.STORE_REGION = [];
      this.STORE_PROVINCE = [];
      this.STORE_DISTRICT = [];
      this.STORE_REGION = await DataREGION.callDataREGION();
      this.STORE_REGION_VIEW = this.STORE_REGION;
      console.log(this.STORE_REGION);
      this.LOADING_PAGE = false;
    },
    async onChangeREGION(values) {
      this.LOADING_PAGE = true;
      this.DATE_PERIODE_SELECTED = "";
      this.DATE_RANGE_SELECTED = null;
      this.DATE_SELECTED = "";
      this.REGION_SELECTED = values;
      this.PROVINCE_SELECTED = "";
      this.DISTRICT_SELECTED = "";
      this.STORE_PROVINCE = [];
      this.STORE_DISTRICT = [];
      this.STORE_PROVINCE = await DataPROVINCE.callDataPROVINCE(this.REGION_SELECTED);
      this.STORE_PROVINCE_VIEW = this.STORE_PROVINCE;
      this.LOADING_PAGE = false;
    },
    async onChangePROVINCE(values) {
      // this.LOADING_PAGE = true;
      // this.DATE_PERIODE_SELECTED   = "";
      // this.DATE_RANGE_SELECTED     = null;
      // this.DATE_SELECTED           = "";
      this.PROVINCE_SELECTED = values;
      // this.DISTRICT_SELECTED       = "";
      // this.STORE_DISTRICT          = [];
      // this.STORE_DISTRICT          = await DataKABUPATEN.callDataKABUPATEN(this.PROVINCE_SELECTED);
      // this.STORE_DISTRICT_VIEW    = this.STORE_DISTRICT;
      // this.LOADING_PAGE            = false;
    },
    async onChangeELEMENT(values) {
      this.ELEMENT_SELECTED = values;
    },
    async onChangeUSAGE(values) {
      this.USAGE_SELECTED = values;
    },
    async onChangeCLASS(values) {
      this.CLASS_SELECTED = values;
    },
    onChangeDISTRICT(values) {
      this.LOADING_PAGE = true;
      this.DATE_PERIODE_SELECTED = "";
      this.DATE_RANGE_SELECTED = null;
      this.DATE_SELECTED = "";
      this.DISTRICT_SELECTED = values;
      this.LOADING_PAGE = false;
    },
    onChangeVIEWTYPE(values) {
      this.VIEW_TYPE = values;
      this.onSearchReport();
    },
    async onSearchReport() {
      this.LOADING_PAGE = true;

      setTimeout(() => {
        this.LOADING_PAGE = false;
      }, 1000);

      // var RESOURCE    = this.RESOURECE_SELECTED;
      // var REGION      = this.REGION_SELECTED;
      // var PROVINCE    = this.PROVINCE_SELECTED;
      // var DISTRICT    = this.DISTRICT_SELECTED;
      // var DATE_PICKER = this.DATE_PERIODE_SELECTED != "" ? this.DATE_PERIODE_SELECTED : ( this.DATE_RANGE_SELECTED != null ? this.DATE_RANGE_SELECTED[0]+" "+this.DATE_RANGE_SELECTED[1] : ( this.DATE_SELECTED != "" ? this.DATE_SELECTED : ""));

      // switch(this.VIEW_TYPE){
      //     case "GRID":
      //         this.REPORT_VISUALITATION = await DataDummyRAWVISUAL.callDataRAWVISUAL(RESOURCE,REGION,PROVINCE,DISTRICT,DATE_PICKER,['region','propinsi','kabupaten','periode','current_latitude','current_longitude'],this.DATA_FLAG);
      //         setTimeout(()=>{
      //             this.$refs.dinamicTableComponent.generateTable(this.REPORT_VISUALITATION );
      //             this.LOADING_PAGE = false;
      //         }, 1000);
      //         break;
      //     case "MAP":
      //         this.REPORT_VISUALITATION = await DataDummyRAWVISUAL.callDataRAWVISUAL(RESOURCE,REGION,PROVINCE,DISTRICT,DATE_PICKER,[],this.DATA_FLAG);
      //         console.log(this.REPORT_VISUALITATION);
      //         var infoHeader = {};
      //         this.HEADER_SHORT_VIEW = [];
      //         for(var i in this.REPORT_VISUALITATION.columns){
      //             var clm = this.REPORT_VISUALITATION.columns[i];
      //             if(infoHeader[clm.field] == undefined){
      //                 infoHeader[clm.field] = clm.label
      //                 this.HEADER_SHORT_VIEW.push({field : clm.field, label : clm.label});
      //             }
      //         }
      //         var marker = [];
      //         for(var i in this.REPORT_VISUALITATION.DATAS){
      //             var infoReport = this.REPORT_VISUALITATION.DATAS[i];
      //             var infoMarker = {};
      //             for(var j in infoReport){
      //                 var rawData = infoReport[j];
      //                 infoMarker[infoHeader[j]]={ flag : (this.DATA_FLAG.indexOf(j) != -1 ? true:false) , values : rawData};
      //             }
      //             marker.push({
      //                 position        : [Number(infoReport.current_latitude),Number(infoReport.current_longitude)],
      //                 //JIKA INGIN MENGCUSTOME ICON
      //                 iconSize           : [15,15],
      //                 // iconAnchor      : [],
      //                 // iconUrl         : "",
      //                 markerInfo         : infoMarker
      //             })
      //         }
      //         this.SET_MARKER = marker;
      //         setTimeout(()=>{
      //             this.$refs.dinamicMapComponent.generateMarker(this.SET_MARKER);
      //             this.LOADING_PAGE = false;
      //         }, 1000);
      //         break;
      //     default:
      //         this.LOADING_PAGE = false;
      //         break;
      // }
    },
    onRowClick(params) {
      // console.log(params);
    },
    onClickMarker(event, params) {
      this.MODEL_INFO = {};
      for (var i in params) {
        var key = i.split(" ").join("_");
        this.MODEL_INFO[key] = params[i];
      }
      this.MODAL_MARKER_SHOW = true;
      console.log(event, params);
    },
    selectionChanged(params) {
      console.log(params);
    },
    modalMarkerClose() {
      this.MODAL_MARKER_SHOW = false;
    },
    modalMarkerOk() {
      this.MODAL_MARKER_SHOW = false;
    },
    moment,
  },
  data() {
    return {
      SEARCH_TEXT: "",
      USAGE_SELECTED: "",
      PROVINCE_SELECTED: "",
      ELEMENT_SELECTED: "",
      CLASS_SELECTED: "",

      USAGE_STORE: [],
      STORE_USAGE: [],
      STORE_PROVINCE: [],
      STORE_ELEMENT: [],
      STORE_CLASS: [],

      STORE_PROVINCE_VIEW: [],
      STORE_ELEMENT_VIEW: [],
      STORE_CLASS_VIEW: [],

      ALLOW_DATE_PICKER: ["1", "2", "21", "22"],
      ALLOW_DATE_RANGE_PICKER: ["NONE"],
      ALLOW_PERIODE_PICKER: ["3", "4", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
      RESOURECE_SELECTED: "",
      REGION_SELECTED: "",

      DISTRICT_SELECTED: "",
      DATE_PERIODE_SELECTED: "",
      DATE_RANGE_SELECTED: [],
      DATE_SELECTED: "",
      STORE_RESOURCE: [],
      STORE_REGION: [],

      STORE_DISTRICT: [],

      STORE_RESOURCE_VIEW: [],
      STORE_REGION_VIEW: [],

      STORE_DISTRICT_VIEW: [],

      STORE_VIEW_TYPE: [
        { key: "GRID", label: "Tabular" },
        { key: "MAP", label: "Map" },
      ],
      VIEW_TYPE: "MAP",
      REPORT_VISUALITATION: { DATAS: [] },
      LOADING_PAGE: true,
      ExportExcelName: "ExportExcel123",
      SET_MARKER: [],
      MODAL_MARKER_SHOW: false,
      MODEL_INFO: {},
      RESOURCE_STORE: [
        { KEY: "1", LABEL: "ME 45", CODE: "ME_45" },
        { KEY: "2", LABEL: "ME 48", CODE: "ME_48" },
        { KEY: "3", LABEL: "FKLIM71", CODE: "FKIKLIM71" },
        { KEY: "4", LABEL: "IKLIM MIKRO", CODE: "IKLIM_MIKRO" },
        { KEY: "5", LABEL: "AGM 1 A", CODE: "AGM1A" },
        { KEY: "6", LABEL: "AGM 1 B", CODE: "AGM1B" },
        { KEY: "7", LABEL: " PSYCHROMETER ASSMAN", CODE: "PSYCHROMETER_ASSMAN" },
        { KEY: "8", LABEL: "SUHU TANAH", CODE: "SUHU_TANAH" },
        { KEY: "9", LABEL: "OP PENGUAPAN", CODE: "OP_PENGUAPAN" },
        { KEY: "10", LABEL: "PIECE PENGUAPAN", CODE: "PICHE_PENGUAPAN" },
        { KEY: "11", LABEL: "LYSIMETER", CODE: "LYSIMETER" },
        { KEY: "12", LABEL: "GUNBELANI", CODE: "GUNBELLANI" },
        { KEY: "13", LABEL: "LAMA PENYINARAN", CODE: "LAMA_PENYINARAN" },
        { KEY: "14", LABEL: "AKTINOGRAPH", CODE: "AKTINOGRAPH" },
        { KEY: "15", LABEL: "THERMOHIGOGRAPH", CODE: "THERMOHIGOGRAPH" },
        { KEY: "16", LABEL: "BAROGRAPH", CODE: "BAROGRAPH" },
        { KEY: "17", LABEL: "PERAWANAN", CODE: "PERAWANAN" },
        { KEY: "18", LABEL: "FORM HUJAN", CODE: "FORM_HUJAN" },
        { KEY: "19", LABEL: "HUJAN HELMAN", CODE: "HUJAN_HELMAN" },
        { KEY: "20", LABEL: "KELEMBAPAN TANAH", CODE: "KELEMBAPAN_TANAH" },
        { KEY: "21", LABEL: "PIBAL", CODE: "PIBAL" },
        { KEY: "22", LABEL: "METAR SPECI", CODE: "METAR_SPECI" },
        // { KEY : "23", LABEL : "PILOT BALON", CODE : "PILOT BALON"},
        // { KEY : "24", LABEL : "PETIR", CODE : "PETIR"},
        // { KEY : "25", LABEL : "MAGNET", CODE : "MAGNET"},
      ],
      HEADER_SHORT_VIEW: [],
      DATA_FLAG: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "00:30",
        "01:30",
        "02:30",
        "03:30",
        "04:30",
        "05:30",
        "06:30",
        "07:30",
        "08:30",
        "09:30",
        "10:30",
        "11:30",
        "12:30",
        "13:30",
        "14:30",
        "15:30",
        "16:30",
        "17:30",
        "18:30",
        "19:30",
        "20:30",
        "21:30",
        "22:30",
        "23:30",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
    };
  },
  mounted: function () {
    // this.onLoadResource();
    this.onLoadUsage();
    this.onLoadPropinsi();
    this.onLoadElements();
    this.onLoadClass();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.header-box-custome {
  background: #3f9cff;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
}

.monitor .vgt-table {
  font-size: 12px !important;
}

[dir="ltr"] .monitor .vgt-table th {
  padding: 5px 25px 5px 5px !important;
}

[dir] .monitor table.vgt-table td {
  padding: 5px 25px 5px 5px !important;
}
</style>
