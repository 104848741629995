import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_USAGE = false;
export default {
    async dummyDataUSAGE(){
        return  new Promise((resolve) => {
            var datas = [
                {key : "1", label:"MKG", values: "MKG"},
                {key : "2", label:"POS_HUJAN", values: "POS HUJAN"},
                {key : "3", label:"RADAR", values: "RADAR"}
            ];
            resolve(datas);
        });
    },

    async storeDataUSAGE(){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_LIST_MANAGE_USAGE();
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataUSAGE(){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_USAGE == true){
                var datas = await this.dummyDataUSAGE();
                resolve(datas);
            }else{
                var thisData = await this.storeDataUSAGE();
                var datas = thisData.data;
                resolve(datas);
            }
        });
    }
}